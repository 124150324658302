@use 'variables';
@use 'mixins';

.labeled-details {
  color: variables.$gray-7;
  border-radius: variables.$radius-small;
  overflow: hidden;

  @media (width <= variables.$screen-sm) {
    border-radius: 0;
    margin: 0 -10px;
  }

  .header {
    font-size: 14px;
    line-height: 1.5;
    padding: 16px;
    background-color: variables.$gray-1;
    border-bottom: 1px solid variables.$gray-2;

    @media (width <= variables.$screen-sm) {
      background-color: transparent;
    }
  }

  li {
    display: grid;
    grid-template-columns: 160px auto;

    @media (width > variables.$screen-sm) {
      &:not(:last-child) {
        border-bottom: 1px solid variables.$gray-2;
      }
    }

    @media (width <= variables.$screen-sm) {
      display: flex;
      flex-direction: column;
    }

    &.invitation-schedule {
      .labeled-details__value {
        row-gap: 8px;
      }
    }

    &.reminders {
      .labeled-details__label {
        grid-row: 1 / 6;
      }

      .labeled-details__value-inner {
        @media (width <= variables.$screen-xs) {
          display: flex;
          flex-direction: column;
        }
      }
    }

    .delta {
      color: variables.$gray-5;
    }
  }

  &__label {
    padding: 16px;
    background-color: variables.$gray-2;
    font-size: 14px;
    line-height: 24px;
    align-self: stretch;
    user-select: none;

    @media (width <= variables.$screen-sm) {
      line-height: 36px;
      padding: 0 16px;
    }

    &:not(:first-of-type) {
      padding-top: 0;
    }

    &:not(:last-of-type) {
      padding-bottom: 4px;

      @media (width <= variables.$screen-sm) {
        padding-bottom: 0;
      }
    }
  }

  &__value {
    display: flex;
    align-items: center;
    padding: 16px;
    font-size: 16px;
    line-height: 24px;
    background-color: variables.$gray-1;
    word-break: break-word;

    @media (width <= variables.$screen-sm) {
      padding: 11px 16px;
      margin-bottom: 8px;
    }

    &:not(:first-of-type) {
      padding-top: 0;

      @media (width <= variables.$screen-sm) {
        padding-top: 11px;
      }
    }

    &:not(:last-of-type) {
      padding-bottom: 4px;

      @media (width <= variables.$screen-sm) {
        padding-bottom: 11px;
      }
    }

    > mat-icon, .icon {
      @include mixins.icon-size(20px);

      color: variables.$gray-4;
      margin-right: 8px;
      user-select: none;
    }

    > app-button {
      align-self: flex-start;
      margin-left: 16px;
    }
  }

  &__value-inner {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-grow: 1;
    gap: 0 16px;

    @media (width <= variables.$screen-xs) {
      gap: 0 8px;
    }

    app-button {
      align-self: center;
    }

    .action-button-wrapper {
      flex-grow: 1;
      text-align: right;
    }
  }

  .date {
    width: fit-content;
    display: inline-grid;
    grid-template-columns: 86px auto auto;
    gap: 16px;

    @media (width <= variables.$screen-xs) {
      gap: 0 8px;
      display: flex;
      flex-wrap: wrap;
    }

    span {
      display: flex;
      align-items: center;
    }
  }

  .luxsave-details-button {
    display: flex;
    align-self: flex-start;
    flex-shrink: 0;
    color: variables.$gray-4;
    background-color: transparent;
    padding: 3px;
    margin: -3px;
    margin-left: 13px;
    border-radius: 50%;
    outline: none;
    transition-duration: 0.2s;

    &:hover,
    &:focus {
      color: variables.$gray-5;
      background-color: variables.$gray-3;
    }
  }

  .limit-error {
    display: flex;
    color: variables.$red-error-5 !important;
  }
}
