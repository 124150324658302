$gray-1: #f9f9fb;
$gray-2: #ededf3;
$gray-3: #dfdfe7;
$gray-4: #b5b6c5;
$gray-5: #989aae;
$gray-6: #6c6d89;
$gray-7: #4e4e6a;
$gray-8: #323149;
$gray-9: #242239;
$gray-10: #14122b;

$red-primary-1: #fff0f5;
$red-primary-2: #fdc9d7;
$red-primary-3: #fcacbc;
$red-primary-4: #f98695;
$red-primary-5: #f35862;
$red-primary-6: #d92a33;
$red-primary-7: #af0d13;
$red-primary-8: #830707;
$red-primary-9: #550202;

$red-error-1: #ffeff1;
$red-error-2: #fcb0b8;
$red-error-3: #fe6d76;
$red-error-4: #f03339;
$red-error-5: #da1c16;
$red-error-6: #bb1711;
$red-error-7: #910a08;
$red-error-8: #620404;
$red-error-9: #3d0101;

$yellow-warning-1: #fffef0;
$yellow-warning-2: #fef39f;
$yellow-warning-3: #fce26e;
$yellow-warning-4: #f1c841;
$yellow-warning-5: #e3a922;
$yellow-warning-6: #c48013;
$yellow-warning-7: #945507;
$yellow-warning-8: #623004;
$yellow-warning-9: #461c01;

$green-success-1: #f0fff6;
$green-success-2: #9cf2be;
$green-success-3: #5be08c;
$green-success-4: #32c866;
$green-success-5: #32a458;
$green-success-6: #1b883c;
$green-success-7: #10651e;
$green-success-8: #044409;
$green-success-9: #002d00;

$screen-lg: 1280px;
$screen-lg-height: 900px;
$screen-md: 1024px;
$screen-sm: 768px;
$screen-xs: 480px;

$nunito: 'Nunito';

$font-weight-bold: 700;

$scrollbar-size: 8px;

$z-info-box: 1001;
$z-modal: 1000;
$z-overlay: $z-modal;
$z-sidebar-mobile-dropdown: 1000;
$z-snackbar: 10000;
$z-loader: 10;
$z-page-header: $z-loader + 1;
$z-sidebar-toggle: $z-page-header + 1;

$radius-small: 4px;
$radius-medium: 6px;
$radius-large: 8px;

$box-shadow-light: 0px 5px 24px rgba(0, 0, 0, 0.05);
$box-shadow-tight: 0px 1px 4px rgba(158, 158, 161, 0.34);
$box-shadow-button: 0px 0px 3px 1px;
$box-shadow-modal: 0px 12px 24px rgba(47, 56, 76, 0.16);
$box-shadow-overlay-header: 0px 0px 5px 1px rgba(47, 56, 76, 0.5);

$page-body-inline-padding: 28px;
$page-body-inline-padding-mobile: 16px;
$page-body-block-padding: 24px;

$modal-side-padding: 24px;
$popup-side-padding-mobile: 16px;
$popup-max-width: 540px;
$popup-overlay-padding: 24px;

$sidebar-width: 244px;
$sidebar-collapsed-width: 88px;
$sidebar-mobile-height: 60px;

$tab-padding: 6px;

$form-field-label-clearance: 6px;
$intercom-button-clearance: 100px;

$table-row-padding-vertical: 9px;
$table-row-padding-horizontal: 12px;
$button-side-padding: 20px;
$form-margin: 32px;
$inline-fields-margin: 18px;
$inline-fields-margin-small: 4px;

$mat-icon-button-width: 40px;

$onb-block-content: 444px;
