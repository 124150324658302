@use 'variables';

.tabs {
  display: flex;
  gap: 4px;
  border-bottom: 1px solid variables.$gray-2;

  button {
    $margin: 6px;

    display: flex;
    justify-content: center;
    color: variables.$gray-6;
    font-size: 16px;
    line-height: 1.5;
    padding: variables.$tab-padding;
    margin: $margin 0;
    border-radius: 12px;
    background-color: transparent;
    transition-duration: 0.2s;
    outline: none;
    position: relative;

    &:after {
      content: '';
      height: 1px;
      width: 0;
      background-color: transparent;
      position: absolute;
      transform: translateY(-5px);
      top: calc(100% + $margin);
      transition-duration: inherit;
    }

    &:not(.active) {
      @media (hover: hover) {
        &:hover {
          background-color: variables.$gray-1;
        }
      }

      &:active {
        background-color: variables.$gray-2;
      }
    }

    &:focus-visible {
      background-color: variables.$gray-2;
    }

    &.active {
      color: variables.$gray-8;

      &:after {
        width: calc(100% - (#{variables.$tab-padding} * 2));
        transform: translateY(0);
        background-color: variables.$gray-8;
      }
    }
  }
}

.ns-tabs {
  display: flex;
  align-items: center;
  user-select: none;

  &--align-center {
    justify-content: center;
    align-self: center;
  }

  &--align-left {
    justify-content: flex-start;
    align-self: flex-start;
  }

  &--align-right {
    justify-content: flex-end;
    align-self: flex-end;
  }

  // TODO: update the class name
  &--new {
    display: inline-flex;
    gap: 12px;
    background-color: white;
    border-radius: 25px;
    overflow-x: auto;
    max-width: 100%;
    box-shadow: variables.$box-shadow-light;

    &::-webkit-scrollbar {
      --scrollbar-size: 0;
    }

    .ns-tab {
      flex-shrink: 0;
      border-radius: inherit;

      &__button {
        color: variables.$gray-6;
        background-color: transparent;
        font-size: 14px;
        line-height: 1.5;
        font-weight: variables.$font-weight-bold;
        padding: 11px 24px;
        border: 1px solid transparent;
        border-radius: inherit;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          top: -1px;
          left: -1px;
          right: -1px;
          bottom: -1px;
          transform: scale(0);
          border: inherit;
          border-radius: inherit;
          transition-duration: inherit;
        }

        &:focus:not(:hover, :active) {
          border-color: variables.$red-primary-2;

          &:before {
            border-color: inherit;
          }
        }

        &:not(:disabled, .ns-tab__button--disabled, .ns-tab__button--active) {
          @media (hover: hover) {
            &:hover {
              color: variables.$gray-6;

              &:before {
                transform: scale(0.85);
                background-color: variables.$gray-2;
              }
            }
          }

          &:active {
            color: variables.$gray-7;
            
            &:before {
              transform: scale(0.8);
              background-color: variables.$gray-3;
            }
          }
        }

        &:disabled, &--disabled {
          color: variables.$gray-4;
          cursor: default;
        }

        &--active {
          color: white;
          
          &:before {
            transform: scale(1);
            background-color: variables.$gray-7;
          }
        }

        .ns-tab-button-text {
          position: relative;
        }
      }
    }
  }

  &--simple {
    gap: 16px;

    .ns-tab {
      &__button {
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 1.5;
        font-weight: variables.$font-weight-bold;
        padding: 7px 20px;
        color: variables.$gray-6;
        background-color: white;
        border: 1px solid transparent;
        border-radius: variables.$radius-small;

        @media screen and (max-width: variables.$screen-xs) {
          font-size: 12px;
          padding: 6px 10px;
        }

        &:focus:not(:hover, :active) {
          border-color: variables.$red-primary-2;
        }

        &:not(:disabled, .ns-tab__button--active) {
          @media (hover: hover) {
            &:hover {
              color: variables.$gray-7;
              background-color: variables.$gray-3;

              .ns-tab__badge {
                color: variables.$gray-7;
                background-color: white;
              }
            }
          }

          &:active {
            color: variables.$gray-7;
            background-color: variables.$gray-4;

            .ns-tab__badge {
              color: variables.$gray-7;
              background-color: white;
            }
          }
        }

        &:disabled {
          color: variables.$gray-4;
          background-color: variables.$gray-2;
        }

        &--active {
          color: white;
          background-color: variables.$gray-7;

          .ns-tab__badge {
            color: variables.$gray-7;
            background-color: white;
          }
        }
      }

      &__badge {
        font-size: 12px;
        color: variables.$gray-5;
        background-color: variables.$gray-2;
        padding: 0 5px;
        margin-left: 6px;
        border-radius: 9px;
        transition-duration: inherit;
      }
    }
  }

  .ns-tab {
    display: inline-flex;

    &__button {
      outline: none;
      transition-duration: 0.2s;
    }
  }
}

.ns-tab-content {
  &--scrollable {
    flex-grow: 1;
    overflow: auto;
  }
}
