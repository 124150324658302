@use 'variables';
@use 'mixins';

.breadcrumbs {
  display: flex;
  align-items: center;
  gap: 4px 0;
  color: variables.$gray-6;
  overflow: hidden;
  user-select: none;

  @media screen and (min-width: calc(#{variables.$screen-sm} + 1px)) {
    flex-wrap: wrap;
  }

  li {
    display: flex;
    align-items: center;

    a, button {
      &:not(.current) {
        @media (hover: hover) {
          &:hover {
            background-color: variables.$gray-2;
          }
        }
  
        &:focus-within {
          background-color: variables.$gray-2;
        }
      }
    }

    &.placeholder {
      @media screen and (min-width: calc(#{variables.$screen-sm} + 1px)) {
        display: none;
      }

      button {
        display: flex;
        gap: 10px;
        color: inherit;
      }
    }

    &:last-child {
      overflow: hidden;

      a {
        @include mixins.ellipsis();
      }
    }

    &:not(:last-child) {
      flex-shrink: 0;
    }

    @media screen and (max-width: variables.$screen-sm) {
      &:not(.root, .placeholder, :last-child) {
        display: none;
      }
    }
  }

  a {
    color: inherit;
    padding: 0 3px;
    margin: 0 1px;

    &.current {
      color: variables.$gray-9;
      pointer-events: none;
    }
  }

  button {
    outline: none;
    background-color: transparent;
  }

  a, button {
    border-radius: variables.$radius-small;
    transition-duration: 0.2s;
  }

  .desktop-link {
    display: flex;
    align-items: center;
  
    @media (width <= variables.$screen-sm) {
      display: none;
    }
  
    mat-icon {
      margin-right: 4px;
    }
  }
  
  .mobile-link {
    display: flex;
    align-items: center;
    gap: 4px;
  
    @media (width > variables.$screen-sm) {
      display: none;
    }
  
    span {
      @include mixins.ellipsis();
    }
  }

  mat-icon {
    @include mixins.icon-size(20px);
  }
}
